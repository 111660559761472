<template>
  <div class="progress-wrapper">
    <div
      class="progress-current"
      :style="{ 'width': progress + '%' }"
    >
      <span
        class="progress-no"
        :style="{ 'left': progress + '%' }"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepProgress',

    props: {
      progress: {
        type: Number,
        default: 0
      }
    }
  };
</script>
