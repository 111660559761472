import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseUrl: process.env.BASE_URL,
    pageData: [],
    answers: [],
    showType: null,
    currentStep: null,
    resultKey: null,
    currentQuestionNo: 1
  },

  mutations: {
    updateContent(state, data) {
      state.pageData = data;
    },

    storeAnswer(state, data) {
      if (state.answers[state.currentQuestionNo - 1] && state.answers[state.currentQuestionNo - 1].question === data.question) { // overwrite
        state.answers[state.currentQuestionNo - 1] = (data);
      } else {
        state.answers.push(data);
      }
    },

    storeResult(state, data) {
      state.resultKey = data;
    },

    storeShowType(state, data) {
      state.showType = data;
    },

    storeQuestionNo(state, data) {
      state.currentQuestionNo = data;
    },

    clearAnswers(state, no) {
      const newAnswers = state.answers.filter((element, index) => {
        return index < Number(no);
      });
      state.answers = newAnswers;
    },

    resetForm(state, result = false) {
      state.answers = [];
      state.currentStep = null;
      state.showType = null;
      state.currentQuestionNo = 1;

      if (!result) {
        state.resultKey = null;
      }
    }
  },

  actions: {
    selectAnswer(context, answer) {
      if (answer) {
        context.commit('storeAnswer', answer);
      }
    },

    setShowType(context, answer) {
      if (answer) {
        context.commit('storeShowType', answer.key);
      }
    },

    selectResult(context, key) {
      if (key) {
        context.commit('storeResult', key);
      }
    },

    currentQuestionNo(context, no) {
      if (no) {
        context.commit('storeQuestionNo', no);
      }
    },

    getContent(context) {
      if (Object.keys(context.state.pageData).length !== 0) return;
      return axios
        .get(context.state.baseUrl + 'data.json')
        .then(response => {
          context.commit('updateContent', response.data.pages);
        });
    }
  }
});
