import Vue from 'vue';
import App from './App.vue';

import VueRouter from 'vue-router';
import router from './router';

import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';

import store from './store/store';

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.config.productionTip = false;

const cookie = document.cookie.split('; ').find(row => row.startsWith('DEN-modelclausules='));
let optIn = false;

if (cookie) {
  if (cookie.split('=')[1] === 'accepted') {
    optIn = true;
  }
}

Vue.use(VueGtag, {
  // config: { id: 'UA-13283815-1' },
  config: { id: '' },
  enabled: optIn
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
