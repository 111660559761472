<template>
  <div class="container">
    <div class="row">
      <div
        v-if="page"
        class="column text-column"
      >
        <StepProgress
          :progress="progress"
        />
        <h1>{{ questionText }}</h1>
        <fieldset>
          <StepAnswerOption
            v-for="(option, index) in questionOptions"
            :key="index"
            :option="option"
            :option-selected="optionSelected === option.key"
            :question-key="question.key"
            @change="selectedOption"
          />
        </fieldset>
        <div class="buttons">
          <button
            v-if="$route.params.step > 1"
            role="link"
            class="btn btn-outline"
            @click="previousStep"
          >
            &lt; Vorige
          </button>
          <button
            role="link"
            class="btn"
            :disabled="Object.keys(selected).length === 0"
            @click="nextStep"
          >
            {{ buttonNextText }} &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StepAnswerOption from '@/components/StepAnswerOption.vue';
  import StepProgress from '@/components/StepProgress.vue';

  import { mapState } from 'vuex';

  export default {
    name: 'Step',

    components: {
      StepAnswerOption,
      StepProgress
    },

    data() {
      return {
        currentQuestionKey: 'type',
        totalProgress: 4,
        selected: Object,
        progress: 0
      };
    },

    computed: {
      page() {
        if (!this.pageData) return false;
        return this.pageData['step'];
      },

      question() {
        if (!this.page) return false;
        return this.page.steps[this.currentQuestionKey];
      },

      questionText() {
        if (typeof this.question.question === 'object') {
          if (this.question.question[this.showType]) {
            return this.question.question[this.showType];
          }
          return this.question.question.default;
        }
        return this.question.question;
      },

      questionOptions() {
        return this.question.options.filter(option => {
          if (option.showForType) {
            return option.showForType.includes(this.showType);
          }
          return true;
        });
      },

      optionSelected() {
        const question = this.answers[this.currentQuestionNo - 1];
        if (question && question.question === this.currentQuestionKey) {
          return question.answer.key;
        }
        return false;
      },

      buttonNextText() {
        if (!this.question.buttonNext) return 'Volgende';
        return this.question.buttonNext;
      },

      totalAnswers() {
        return this.answers.length;
      },

      ...mapState(['pageData', 'answers', 'showType', 'currentQuestionNo'])
    },

    created() {
      let step = this.$route.params.step;

      if (!step || isNaN(step)) {
        return this.$router.push({ path: '/step/1' }).then(() => window.scrollTo(0, 0));
      }

      if (Number(step) !== 1 && step > this.totalAnswers) {
        this.$store.commit('resetForm');
        return this.$router.push({ path: '/step/1' }).then(() => window.scrollTo(0, 0));
      } else if (Number(step) !== 1 && Number(step) === this.totalAnswers) {
        this.selected = this.answers[step - 1]; // see if selected + questionkey is available
        this.currentQuestionKey = this.answers[step - 1].question;
      }
    },

    methods: {
      nextStep() {
        if (this.currentQuestionKey === 'type') {
          this.$store.dispatch('setShowType', this.selected);
        }

        const questionAndAnswer = {
          'question': this.currentQuestionKey,
          'answer': this.selected
        };

        this.$store.dispatch('selectAnswer', questionAndAnswer);
        this.$store.dispatch('currentQuestionNo', this.$route.params.step);

        if (!this.selected.next && this.selected.result) {
          this.showResult();
        } else {
          this.currentQuestionKey = this.selected.next;
          this.selected = Object; // clear selected

          return this.$router.push({ path: `/step/${Number(this.$route.params.step) + 1}` }).then(() => window.scrollTo(0, 0));
        }
      },

      previousStep() {
        return this.$router.push({ path: `/step/${Number(this.$route.params.step) - 1}` }).then(() => window.scrollTo(0, 0));
      },

      updateProgress(no) {
        const decreaseProgress = ['makerDiedOver70', 'existing', 'av'];
        if (decreaseProgress.includes(this.selected.key)) {
          this.totalProgress = 3;
        }
        this.progress = ((no - 1) / this.totalProgress) * 100;
      },

      showResult() {
        this.$store.dispatch('selectResult', this.selected.result);
        return this.$router.push({ path: '/result' }).then(() => window.scrollTo(0, 0));
      },

      selectedOption(newValue) {
        this.selected = newValue;
      }
    },

    watch: {
      $route(to, from) {
        if (this.answers.length > 0 && from.params.step > to.params.step) {
          this.$store.commit('clearAnswers', to.params.step);
          this.$store.dispatch('currentQuestionNo', to.params.step);
          this.currentQuestionKey = this.answers[to.params.step - 1].question;
        }
        this.updateProgress(to.params.step);
      }
    },

    metaInfo() {
      return {
        title: 'Vraag'
      };
    }
  };
</script>
