<template>
  <div class="box box-bordered">
    <ParagraphBlock
      :content="text"
    />
  </div>
</template>

<script>
  import ParagraphBlock from '@/components/ParagraphBlock.vue';

  export default {
    name: 'TextPublication',

    components: {
      ParagraphBlock
    },

    computed: {
      text() {
        return [
          'Misschien weet je nu al hoe je het materiaal wilt publiceren, bijvoorbeeld via je eigen website of via een social media kanaal. Via een bestaand social media kanaal heb je te maken met de gebruikersvoorwaarden van dat kanaal. Dat kan bijvoorbeeld bepalen hoe lang het materiaal beschikbaar blijft. Als je zelf iets ontwikkelt, kun je in het contract met de partij die de technische ontwikkeling doet, de volgende clausule opnemen:',
          'Door [dienstverlener X] worden geen verdergaande garanties verlangd dan[podiumkunstinstelling Y] in staat is om te verlenen. [podiumkunstinstelling Y] bedingt hierbij omgekeerd de garantie van [dienstverlener X] dat het gebruik van de content nooit (in duur, plaats, aard of omvang) de grenzen overschrijdt van het overeengekomen gebruik/de overeengekomen dienst.'
        ];
      }
    }
  };
</script>
