<template>
  <div id="app">
    <PageHeader />
    <main>
      <router-view />
    </main>
    <PageFooter />
    <CookieBanner />
  </div>
</template>

<script>
  const metaDescription = 'Modelclausules voor podiumkunstinstellingen voor het digitale hergebruik van bijvoorbeeld audio of beeldmateriaal.';

  import PageHeader from '@/components/PageHeader.vue';
  import PageFooter from '@/components/PageFooter.vue';
  import CookieBanner from './components/CookieBanner.vue';

  export default {
    components: {
      PageHeader,
      PageFooter,
      CookieBanner
    },

    created() {
      this.$store.dispatch('getContent');
    },

    metaInfo() {
      return {
        title: 'Modelclausules Digitaal hergebruik podiumkunsten',
        titleTemplate: '%s | Modelclausules Digitaal hergebruik podiumkunsten | DEN',
        htmlAttrs: {
          lang: 'nl'
        },
        meta: [
          { name: 'description', content: metaDescription },
          { name: 'og:description', content: metaDescription }
        ]
      };
    }
  };
</script>

<style lang="scss">
  @import "~normalize.css/normalize.css";
  @import "./design";
</style>
