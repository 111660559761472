<template>
  <div>
    <label class="radio">
      <input
        type="radio"
        :value="option.key"
        :name="questionKey"
        v-model="selected"
        @change="selectOption()"
      />
      <span>{{ option.answer }}</span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'StepAnswerOption',

    props: {
      option: Object,
      questionKey: String,
      optionSelected: Boolean
    },

    data() {
      return {
        selected: this.optionSelected ? this.option.key : null
      };
    },

    watch: {
      questionKey() {
        this.$nextTick(() => {
          this.selected = this.optionSelected ? this.option.key : null;
          this.selectOption();
        });
      }
    },

    methods: {
      selectOption() {
        this.$emit('change', this.option);
      }
    }
  };
</script>
