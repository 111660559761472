import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Step from '../views/Step.vue';
import Result from '../views/Result.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/step/:step?',
    name: 'Step',
    component: Step
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
