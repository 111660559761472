<template>
  <div
    v-if="page"
    class="container"
  >
    <div class="row">
      <div class="column text-column">
        <StepProgress
          :progress="100"
        />
        <h1>{{ page.title }}</h1>
        <ParagraphBlock
          :content="result.text"
        />
        <div
          v-if="filteredClauses"
          class="margin-top-3"
        >
          <h2>Voorbeeld modelclausules</h2>
          <div
            v-for="(clause, index) in filteredClauses"
            :key="index"
            class="box"
          >
            <ParagraphBlock
              :content="clauses(clause).text"
            />
          </div>
        </div>
        <TextPublication />
        <div class="buttons">
          <button
            role="link"
            class="btn"
            @click="resetForm"
          >
            Begin opnieuw
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ParagraphBlock from '@/components/ParagraphBlock.vue';
  import TextPublication from '@/components/TextPublication.vue';
  import StepProgress from '@/components/StepProgress.vue';

  import { mapState } from 'vuex';

  export default {
    name: 'Result',

    components: {
      ParagraphBlock,
      StepProgress,
      TextPublication
    },

    computed: {
      page() {
        if (!this.pageData) return false;
        return this.pageData['result'];
      },

      result() {
        return this.page.results[this.resultKey];
      },

      filteredClauses() {
        if (!this.result.clause) return false;
        if (this.result.clause[this.showType]) {
          return this.result.clause[this.showType];
        } else if (this.result.clause['default']) {
          return this.result.clause['default'];
        }
        return this.result.clause;
      },

      ...mapState(['pageData', 'resultKey', 'showType', 'answers'])
    },

    data() {
      return {
        summary: Object
      };
    },

    created() {
      if (!this.resultKey) {
        return this.$router.push({ path: '/' }).then(() => window.scrollTo(0, 0));
      }
      this.summary = this.answers;
      this.$store.commit('resetForm', true);
    },

    mounted() {
      window.addEventListener('SurvicateReady', () => {
        // window._sva.destroyVisitor(window._sva.retarget);
        window._sva.showSurvey('7dd5665e15490ac0');
      });
    },

    methods: {
      resetForm() {
        this.$store.commit('resetForm');
        return this.$router.push({ path: '/' }).then(() => window.scrollTo(0, 0));
      },

      clauses(clauseKey) {
        return this.pageData['clause'].clauses[clauseKey];
      }
    },

    metaInfo() {
      return {
        title: 'Resultaat',
        script: [
          { src: 'https://survey.survicate.com/workspaces/5aa067119d706c45f8f486b4fe351cd5/web_surveys.js', async: true, body: true }
        ]
      };
    }
  };
</script>
