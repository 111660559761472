<template>
  <div
    v-if="page"
    class="container"
  >
    <div class="row">
      <div class="column text-column">
        <h1>{{ page.title }}</h1>
        <ParagraphBlock
          :content="page.text"
        />
        <TextPublication />
        <div class="buttons">
          <button
            role="link"
            class="btn"
            @click="startForm"
            @keypress.enter="startForm"
          >
            Start &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ParagraphBlock from '@/components/ParagraphBlock.vue';
  import TextPublication from '@/components/TextPublication.vue';
  import { mapState } from 'vuex';

  export default {
    name: 'Home',

    components: {
      ParagraphBlock,
      TextPublication
    },

    computed: {
      page() {
        if (!this.pageData) return false;
        return this.pageData['home'];
      },
      ...mapState(['pageData'])
    },

    created() {
      this.$store.commit('resetForm');
    },

    methods: {
      startForm() {
        this.$store.commit('resetForm');
        return this.$router.push({ path: '/step/1' }).then(() => window.scrollTo(0, 0));
      }
    },

    metaInfo() {
      return {
        title: 'Intro'
      };
    }
  };
</script>
