<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-for="(paragraph, index) in content"
      :key="index"
      v-html="paragraph"
    />
    <!-- eslint-disable vue/no-v-html -->
  </div>
</template>

<script>
  export default {
    name: 'ParagraphBlock',

    props: {
      content: {
        type: Array,
        default: () => []
      }
    }
  };
</script>
